<template>
	<div class="main"
	v-loading="loading" 
			element-loading-text="数据加载中"
			element-loading-spinner="el-icon-loading"
			element-loading-background="rgba(255, 255, 255, 1)"
			:option="option"
	>
		<div class="mian-center">
			<div class="top flex">
				<div>
					<div class="top-row1">
						<div class="flex">
							<div class="lable1">1239</div>
							<div class="lable2">RTS项目总数(个）</div>
						</div>
						<!-- <div style="margin-left: -1vw;" class="flex">
							<div class="flex">
								<div class="name">在线设备</div>
								<div class="num">{{ online }}</div>
								<div class="name">台</div>
							</div>
							<div style="margin-left: 2vw;" class="flex">
								<div class="name">离线设备</div>
								<div class="num">{{ drop_line }}</div>
								<div class="name">台</div>
							</div>
						</div> -->
					</div>
				</div>
				<div class="top-row2" id="chartPie" :option="option"></div>
				<dv-capsule-chart :config="config" class="top-row3" />
			</div>
			<div>
				<div class="chaxu">
					<el-form ref="form" :model="form" label-width="100px" class="flex">
						<el-form-item label="项目名称：">
							<el-input v-model="form.name" class="bbb"></el-input>
						</el-form-item>
						<el-form-item label="项目所在地：">
							<el-input v-model="form.region" class="bbb"></el-input>
						</el-form-item>
						<!-- <el-form-item label="项目所在地：">
                        <el-select v-model="form.region" placeholder="请选择所在地" class="region">
                        <el-option label="区域一" value="shanghai"></el-option>
                        <el-option label="区域二" value="beijing"></el-option>
                        </el-select>
                    </el-form-item> -->
						<el-form-item label="项目类型：">
							<el-select v-model="form.region1" placeholder="请选择项目类型" @change="search" clearable>
								<el-option label="全部" value=""></el-option>
								<el-option label="热风炉" value="rfl"></el-option>
								<el-option label="加热炉" value="jrl"></el-option>
								<el-option label="加热炉二级" value="jrlse"></el-option>
								<el-option label="燃气炉" value="rql"></el-option>
								<el-option label="烧结机" value="sjj"></el-option>
								<el-option label="石灰窑(回转窑)" value="hzy"></el-option>
								<el-option label="石灰窑(双梁式)" value="sls"></el-option>
								<el-option label="竖炉优化" value="slyh"></el-option>

								<el-option label="CFB" value="cfb"></el-option>
								<el-option label="煤粉炉" value="mfl"></el-option>
								<el-option label="环保岛" value="hbd"></el-option>
								<el-option label="燃烧装置" value="rszz"></el-option>
								<el-option label="蒸汽管网" value="zqgw"></el-option>
								<el-option label="电除尘" value="dcc"></el-option>

								<el-option label="化工装置" value="hgzz"></el-option>
								<el-option label="精馏塔" value="jlt"></el-option>
								<el-option label="反应釜" value="fyf"></el-option>
								<el-option label="PVC旋风干燥" value="pvcx"></el-option>
								<el-option label="PVC流化床干燥" value="pvcl"></el-option>
								<el-option label="水泥生产线" value="snscx"></el-option>
								<el-option label="水泥磨" value="snm"></el-option>
								<el-option label="硫回收" value="lhs"></el-option>
								<el-option label="合成氨" value="hca"></el-option>
								<el-option label="焙烧炉" value="bsl"></el-option>
								<el-option label="聚合釜" value="jhf"></el-option>
								<el-option label="专业培训" value="train"></el-option>

							</el-select>
							<el-button type="primary" class="subtn" @click="search">搜索</el-button>
							<el-button type="primary" @click="yjMap" class="subtn">业绩地图</el-button>
							<el-button type="primary" @click="toCeipage" class="subtn">CEI统计</el-button>
							<!-- <el-button type="primary"  class="subtn" @click="addsut">新增项目</el-button> -->
						</el-form-item>
						<!-- <a href="TestCallEXE://" >Test to call local EXE</a> -->
					</el-form>
				</div>
				<div class="main-table">
					<el-table class="table-h" :data="tableData"
						style="width: 100%；font-family: PingFang-SC-Regular;font-size:0.9vw" height="32vh"
						:row-class-name="tableRowClassName" fixed>
						<el-table-column prop="project_name" :show-overflow-tooltip='true' label="项目名称" align="center"
							width="230">
							<template slot-scope='scope'>
								<div class="online" @click="openDetails(scope.row)" style="cursor: pointer;">{{scope.row.project_name}}</div>
							</template>
						</el-table-column>
						<el-table-column label="通讯状态" align="center" width="95">
							<template slot-scope="scope">
								<div class="online" :style="{background: scope.row.online ? '#33c412' : 'red'}"
									style="border-radius: 5px;font-size:13px">
									{{scope.row.online ? '在线' : '离线'}}
								</div>
							</template>
						</el-table-column>
						<el-table-column prop="project_start_day" align="center" label="接入RTS日期" sortable width="130">
						</el-table-column>
						<el-table-column prop="salesperson" align="center" label="商务经理">
						</el-table-column>
						<el-table-column prop="engineer" align="center" label="工程经理">
						</el-table-column>
						<el-table-column prop="product_type_name" align="center" label="项目类型" width="110">
						</el-table-column>
						<el-table-column prop="place" align="center" label="项目所在地" width="105" show-overflow-tooltip>
						</el-table-column>
						<el-table-column align="center" label="VPN状态" width="95">
							<template slot-scope='scope'>
								<div id="vpnOpen1" @click="openVPN(scope.row)">{{scope.row.vpn_status==1?"在线":"断开"}}
								</div>
							</template>
						</el-table-column>
						<el-table-column align="center" label="远程桌面">
							<template slot-scope='scope'>
								<div class="online" @click="openmstsc(scope.row)">连接</div>
							</template>
						</el-table-column>
						<el-table-column prop="url" align="center" label="WEB访问" width="95">
							<template slot-scope='scope'>
								<div class="online" @click="openWeb(scope.row)">访问</div>
							</template>
						</el-table-column>
						<el-table-column align="center" label="数采1"
							v-if="this.UserInfo[1]=='hero'||this.UserInfo[1]=='李鹏'">
							<template slot-scope='scope'>
								<div id="" @click="updateNum(scope.row)">更新</div>
							</template>
						</el-table-column>
						<el-table-column align="center" label="数采2"
							v-if="this.UserInfo[1]=='hero'||this.UserInfo[1]=='李鹏'">
							<template slot-scope='scope'>
								<div id="" @click="updateNum1(scope.row)">更新</div>
							</template>
						</el-table-column>
						<el-table-column align="center" label="重启数采"
							v-if="this.UserInfo[1]=='hero'||this.UserInfo[1]=='李鹏'">
							<template slot-scope='scope'>
								<div id="" @click="restartNum(scope.row)">重启</div>
							</template>
						</el-table-column>

					</el-table>
					<el-pagination class="jiange" @size-change="handleSizeChange" @current-change="handleCurrentChange"
						:current-page="currentPage" :page-sizes="pageSizes" :page-size="PageSize"
						layout="total, sizes, prev, pager, next, jumper" :total="totalCount">
					</el-pagination>
				</div>
			</div>
		</div>
		<el-dialog :visible.sync="centerDialogVisible" width="50vw" custom-class="addPro" :show-close="false" center>
			<div class="addBox ">
				<div class="evaluate-header">
					{{tit}}
					<div class="evaluate-header-icon" @click="centerDialogVisible = false">
						<!-- <span class="el-icon-close"></span> -->
						<img src="~@/assets/images/close.png" style="width: 100%; height: auto" />
					</div>
				</div>
				<!-- 表单 -->
				<div class="flex">
					<el-form :label-position="labelPosition" label-width="120px" :model="formLabelAlign" class="addform"
						:rules="rules" ref="ruleForm">
						<el-form-item label="设备号：" prop="device_name">
							<el-input v-model="formLabelAlign.device_name" clearable class="bbb"></el-input>
						</el-form-item>
						<el-form-item label="项目名称：" prop="project_name">
							<el-input v-model="formLabelAlign.project_name" clearable class="bbb"></el-input>
						</el-form-item>
						<el-form-item label="项目开始时间：" prop="project_start_day">
							<el-date-picker v-model="formLabelAlign.project_start_day" type="date" placeholder="选择日期"
								format="yyyy-MM-d-日" value-format="yyyy-MM-dd">
							</el-date-picker>
							<!-- <el-input v-model="" class="bbb"></el-input> -->
						</el-form-item>
						<el-form-item label="商务经理：" prop="salesperson">
							<el-input v-model="formLabelAlign.salesperson" clearable class="bbb"></el-input>
						</el-form-item>
						<el-form-item label="工程经理：" prop="engineer">
							<el-input v-model="formLabelAlign.engineer" clearable class="bbb"></el-input>
						</el-form-item>
						<el-form-item label="项目所在地：" prop="place">
							<el-input v-model="formLabelAlign.place" clearable class="bbb"></el-input>
						</el-form-item>
						<el-form-item label="项目类型名称：" prop="product_type_name">
							<el-input v-model="formLabelAlign.product_type_name" clearable class="bbb"></el-input>
						</el-form-item>
						<el-form-item label="项目类型代码：" prop="product_type">
							<el-input v-model="formLabelAlign.product_type" clearable class="bbb"></el-input>
						</el-form-item>
					</el-form>
					<!-- <el-button >确定</el-button> -->
				</div>

				<div class="btn" @click="onsubmit('ruleForm')">确定</div>
			</div>
		</el-dialog>
		<el-dialog title="" :visible.sync="dialogVisible" :show-close="false" custom-class="anyDeskPop">
			<div class="PopHt">
				<div class="param-header-icon"  @click="dialogVisible = false">
					<img src="~@/assets/images/close.png" style="width: 100%; height: auto" />
				</div>
				<div class="PopHt-tit">远程桌面连接</div>
				<div class="dialog-content">
					<el-form ref="form" :model="formData" label-width="7vw">
						<el-form-item style="font-size: 1.5vw;" label="系统IP:">
							<!-- <div style="font-size: 2vh;">{{formData.sysip}}</div> -->
							<el-input style="width: 10vw;" v-model="formData.sysip" readonly></el-input>
							<el-button style="width:11vw; font-size: 0.9vw; margin-left: 1vw;" type="primary"
								@click="onCopyIP">复制IP</el-button>

						</el-form-item>

						<br>
						<!-- <el-form-item style="font-size: 3vh;" label="IP地址:">
							<div style="font-size: 2vh;">{{formData.sysip}}</div>
						</el-form-item>
						<br> -->
						<el-form-item style="font-size: 3vh;" label="密码:">
							<!-- <div style="font-size: 2vh;">{{formData.syspassword}}</div> -->
							<el-input style="width: 10vw;" v-model="formData.syspassword" type="password"
								readonly></el-input>

							<el-button style="width:11vw; font-size: 0.8vw;margin-left: 1vw;" type="primary"
								@click="onCopy">&nbsp;&nbsp;复制密码&nbsp;&nbsp;</el-button>

						</el-form-item>
					</el-form>
				</div>
				<div class="PopHt-note">注：远程软件为anydesk</div>
			</div>
			<span slot="footer" class="dialog-footer" style="margin-right: 1.5vw;">

				<el-button @click="dialogVisible = false">取 消</el-button>
				<!-- <el-button type="primary" @click="dialogVisible = false">复制密码</el-button> -->
			</span>
		</el-dialog>

	</div>
</template>
<script>
	import * as echarts from "echarts";
	require("echarts/theme/macarons"); //引入主题
	import {
		authProjectlist,
		vpnLink
	} from "@/api/otherview/index" //用户有权查看的列表
	import Format from "../../utils/auth.js"
	import {
		panelList
	} from "@/api/otherview/index" //面板统计
	import {
		getSpread,
	} from "@/api/index/index";
	import global from '@/utils/ws.js'
	import {
		log_add
	} from "@/api/auth/index"

	// deletList
	export default {
		data() {
			return {
				// openVpnHtml:'打开VPN',
				totalNum: 0,
				edittype: 0,
				drop_line: 0,
				online: 0,
				labelPosition: 'right',
				formLabelAlign: {
					device_name: '',
					project_name: '',
					project_start_day: '',
					salesperson: '',
					engineer: '',
					place: '',
					product_type: '',
					product_type_name: ''
				},
				echartsdata: [],
				rules: {
					device_name: [{
						validator: Format.FormValidate.Form().Username, //validator： 自定义验证规则
						trigger: "blur",
						message: '请正确输入设备号',
					}],
					project_name: [{
							required: true,
							message: '请输入项目名称',
							trigger: 'blur'
						},
						{
							min: 3,
							max: 10,
							message: '长度在 3 到 10 个字符',
							trigger: 'blur'
						}
					],
					project_start_day: [{
						required: true,
						message: '请选择时间',
						trigger: 'blur'
					}, ],
					salesperson: [{
						validator: Format.FormValidate.Form().Name, //validator： 自定义验证规则
						trigger: "blur",
						message: '请正确输入姓名',
					}],
					engineer: [{
						validator: Format.FormValidate.Form().Name, //validator： 自定义验证规则
						trigger: "blur",
						message: '请正确输入姓名',
					}],
					place: [{
						required: true,
						message: '请输入地点',
						trigger: 'blur'
					}, ],
					product_type: [{
						validator: Format.FormValidate.Form().Username, //validator： 自定义验证规则
						trigger: "blur",
						message: '请正确输入项目类型',
					}],
					product_type_name: [{
							required: true,
							message: '请输入项目类型名称',
							trigger: 'blur'
						},
						{
							min: 3,
							max: 10,
							message: '长度在 3 到 10 个字符',
							trigger: 'blur'
						}
					]
				},
				tit: '新增项目',
				centerDialogVisible: false,
				chartPie: null,
				currentPage: 1, //初始页
				PageSize: 9, //每页的数据
				totalCount: 1239,
				loading: false,
				// pageSizes: [10, 20, 30, 40, 50, 100],
				pageSizes: [9, 18, 27, 40, 50, 100],
				UserInfo: [],
				authsee: false,
				form: {
					name: '',
					region: '',
					region1: '',
				},
				config: {

				},
				tableData: [],
				newArry: [],
				option: '',
				dialogVisible: false,
				formData: {
					sysuser: '',
					sysip: '',
					syspassword: ''
				},
				mstcurl: '',
				panel: [{
						name: "热风炉",
						value: 302
					},
					{
						name: "加热炉",
						value: 189
					},
					{
						name: "加热炉二级",
						value: 89
					},
					{
						name: "煤粉炉",
						value: 140
					},
					{
						name: "电除尘",
						value: 38
					},
					{
						name: "CFB",
						value: 123
					},
					{
						name: "燃气炉",
						value: 243
					},
					{
						name: "烧结机",
						value: 88
					},
					{
						name: "焙烧炉",
						value: 27
					},
				]
			}
		},
		ws: {},
		watch: {
			// 
			option: {
				handler(newVal, oldVal) {
					if (this.myChart) {
						if (newVal) {
							this.loading = false
							this.chartPie.setOption(newVal, true);
						} else {
							this.chartPie.setOption(oldVal);
						}
					} else {
						this.drawPieChart();
					}
				},
			}
		},
		created() {
			this.UserInfo = JSON.parse(localStorage.getItem("UserInfo"))
			this.authInfo = JSON.parse(localStorage.getItem("authInfo"))
			this.gethisInfo()
		},
		mounted() {
			this.panelInfo();
			this.getData();
			// this.global.localSocket();
			//  this.drawPieChart();
		},
		ws: {},
		methods: {

			//获取在线离线设备数量
			getData() {
				getSpread({})
					.then((res) => {
						this.online = res.online;
						this.drop_line = res.drop_line;
						// console.log(res.online);
						// console.log(res.drop_line);
					})
			},
			// 打开业绩地图
			yjMap() {
				const data = {
					user: this.UserInfo[1],
					event: "访问业绩地图页面"
				}
				log_add(data)
				this.$router.push({
					path: '/mapIndex'
				})
			},
			// 打开CEI统计
			toCeipage() {
				const data = {
					user: this.UserInfo[1],
					event: "访问cei统计页面"
				}
				log_add(data)
				this.$router.push({
					path: "/CeiPage"
				});
			},
			// 搜索
			search() {
				this.currentPage = 1
				this.gethisInfo()
			},
			handleSizeChange(val) {
				this.PageSize = val
				this.gethisInfo()
			},
			handleCurrentChange(val) {
				this.currentPage = val
				this.gethisInfo()
			},
			//前端自己分页
			getResultsData() {
				// this指向改一下
				let that = this;
				let list = that.tableData; //后端回来表格的数据
				//渲染的数据newArry赋值
				this.newArry = list.filter(
					(item, index) =>
					index < that.currentPage * that.PageSize &&
					index >= that.PageSize * (that.currentPage - 1)
				); //根据页数显示相应的内容
				// this.totalCount = list.length;
				this.totalCount = 1239;
			},
			drawPieChart() {
				let mytextStyle = {
					color: "#fff",
					fontSize: 12,
					fontFamile: "PingFang-SC-Medium",
					fontStyle: "normal",
				};
				let mylabel = {
					show: true,
					position: "left",

					offset: [10, 20],
					formatter: "{b} : {c} ({d}%)",
					textStyle: mytextStyle,
				};
				this.chartPie = echarts.init(
					document.getElementById("chartPie"),
					"macarons"
				);
				this.chartPie.setOption(this.option);
			},
			gethisInfo() {
				authProjectlist({
						auth: {
							"account": this.UserInfo[1]
						},
						search: {
							"project_name": this.form.name,
							"place": this.form.region,
							"product_type": this.form.region1
						},
						page:this.currentPage,
						limit:this.PageSize
					})
					.then(res => {
						this.tableData = res.data
						this.totalCount = res.total
						if (res.vpnHtml) {
							console.log("有定义")
						} else {
							for (let k in this.tableData) {
								this.$set(this.tableData[k], 'vpnHtml', '开启')
								this.$set(this.tableData[k], 'vpnHtml1', '关闭')
								// this.tableData[k].vpnHtml = '打开VPN'
							}
						}

						
					})
					.catch(err => {
						this.loading = false
						// this.$message.error('历史数据获取失败');
					})
			},
			panelInfo() {
				this.loading = true
				panelList({
						account: this.UserInfo[1]
					})
					.then(res => {
						// console.log(res)
						this.loading = false
						this.totalNum = 1239
						this.config = {
							data: this.panel,
							colors: ["#4089ff", "#ff5599", "#fdd843", "#fd6a43", "#25d096"],
							unit: "单位",
							showValue: true,
						}
						this.echartsdata = this.panel
						this.option = {
							tooltip: {
								trigger: "item",
								formatter: "{a} <br/>{b} : {c} ({d}%)",
								position: ['-1%', '50%']
							},
							color: ["#4089ff", "#ff5599", "#fdd843", "#fd6a43", "#25d096"],
							series: [{
								name: '',
								type: 'pie',
								radius: ['50%', '60%'], //饼图大小
								labelLine: { //图形外文字线
									normal: {
										length: 5,
										length2: 10
									}
								},
								label: {
									normal: {
										formatter: '{c|{c}}  \n  {b|{b}}', //图形外文字上下显示
										borderWidth: 50,
										borderRadius: 4,
										// padding: [1, -60],          //文字和图的边距
										rich: {
											a: {
												color: '#333',
												fontSize: 13,
												lineHeight: 20
											},
											b: { //name 文字样式
												fontSize: 13,
												lineHeight: 30,
												color: '#CDCDD0',
											},
											c: { //value 文字样式
												fontSize: 13,
												lineHeight: 20,
												color: '#63BF6A',
												align: "center"
											}
										}
									}
								},
								data: this.panel,
							}],
						}
					})
					.catch(err => {
						this.loading = false
						// this.$message.error('历史数据获取失败');
					})
			},
			tableRowClassName({
				row,
				rowIndex
			}) {
				row.index = rowIndex;
			},
			openVPN(row) {
				console.log(row.vpn_status, "vpn状态")
				if (row.vpn_status == 0 || row.vpn_status == "断开" || row.vpn_status == undefined) {
					//操作日志

					this.$confirm('确定开启VPN操作吗？', '温馨提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'success'
					}).then(() => {
						// 用户点击了确定按钮
						// 在这里编写具体的操作代码
						const data = {
							user: this.UserInfo[1],
							event: row.project_name + "开启vpn操作"
						}
						log_add(data)
						let params = {
							device_name: row.device_name,
							active: 1
						};
						vpnLink(params).then((res) => {
							if (res.device_name = row.device_name) {
								this.$message.success('VPN已开启');
							}
							row.vpn_status = "1";
						})
					}).catch(() => {
						// 用户点击了取消按钮
						// 在这里编写具体的操作代码
						this.$message({
							type: 'info',
							message: '操作已取消'
						});
					});
				} else {
					this.$confirm('vpn已开启，确定断开VPN吗？', '温馨提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {
						// 用户点击了确定按钮
						// 在这里编写具体的操作代码
					}).then(() => {
						// 用户点击了确定按钮
						// 在这里编写具体的操作代码
						const data = {
							user: this.UserInfo[1],
							event: row.project_name + "关闭vpn操作"
						}
						log_add(data)
						let params = {
							device_name: row.device_name,
							active: 0
						};
						vpnLink(params).then((res) => {
							if (res.device_name = row.device_name) {
								this.$message.success('VPN已断开');
							}
							console.log(row.vpn_status)
							row.vpn_status = "断开";
						})
					}).catch(() => {
						// 用户点击了取消按钮
						// 在这里编写具体的操作代码
						this.$message({
							type: 'info',
							message: '操作已取消'
						});
					});
				}
				// console.log(row)
			},

			updateNum(row) {
				let result = this.authInfo.filter(item => item.id === row.device_name + '_w')
				if (result.length == '1') {
					let params = {
						device_name: row.device_name,
						active: 2
					};
					console.log(params);
					vpnLink(params).then((res) => {
						if (res.device_name = row.device_name) {
							this.$message.success('数采1已更新');
						}
					}).catch(err => {
						this.$message.error("未知错误，请联系管理员");
					})
				} else {
					this.$message.success("您没有VPN连接权限");
				}
				// console.log(row)
			},
			updateNum1(row) {
				let result = this.authInfo.filter(item => item.id === row.device_name + '_w')
				if (result.length == '1') {
					let params = {
						device_name: row.device_name,
						active: 4
					};
					console.log(params);
					vpnLink(params).then((res) => {
						if (res.device_name = row.device_name) {
							this.$message.success('数采2已更新');
						}
					}).catch(err => {
						this.$message.error("未知错误，请联系管理员");
					})
				} else {
					this.$message.success("您没有VPN连接权限");
				}
				// console.log(row)
			},
			restartNum(row) {
				// let x = document.getElementById("vpnOpen1");
				// let y = document.getElementById("vpnClose1");
				let result = this.authInfo.filter(item => item.id === row.device_name + '_w')
				if (result.length == '1') {
					let params = {
						device_name: row.device_name,
						active: 3
					};
					// console.log(params, 'VPN断开这边')
					vpnLink(params).then((res) => {
						if (res.device_name = row.device_name) {
							this.$message.success("数采已重启");
						}
					}).catch(err => {
						this.$message.error("未知错误，请联系管理员");
					})
				} else {
					this.$message.success("您没有VPN连接权限");
				}

			},
			// 打开不同的项目类型
			openDetails(row) {
				const data = {
					user: this.UserInfo[1],
					event: "点击进入项目" + row.project_name
				}
				log_add(data)
				// sessionStorage.setItem("deviceInfo", JSON.stringify({type:'rfl'}))
				localStorage.setItem("deviceType", JSON.stringify({
					type: row.product_type,
					device: row.device_name,
					project: row.project_name
				}))
				let authinfo = JSON.parse(localStorage.getItem("authInfo"))
				localStorage.removeItem('spotArr')
				let autharr = []
				for (let i in authinfo) {
					if (authinfo[i].pid === row.device_name) {
						autharr.push(authinfo[i].id)
					}
				}
				localStorage.setItem("autharr", JSON.stringify(autharr))
				switch (row.product_type) {
					case 'rfl':
						return this.$router.push({
							path: '/index'
						});
					case 'jrl':
						return this.$router.push({
							path: '/Jrindex'
						});
					case 'rql':
						return this.$router.push({
							path: '/Rqindex'
						});
					case 'cfb':
						return this.$router.push({
							path: '/Cfbindex'
						});
					case 'mfl':
						return this.$router.push({
							path: '/Mfindex'
						});
					case 'fyf':
						return this.$router.push({
							path: '/Fyindex'
						});
					case 'jhf':
						return this.$router.push({
							path: '/Jhindex'
						});
						// if(row.project_name.includes('中盐吉兰泰')){
						// return this.$router.push({
						// 	path: '/Jhindex_Zy'
						// });
						// }else{
							
						// }
					case 'jlt':
						if(row.project_name.includes('和隆优化仿真')){
						return this.$router.push({
							path: '/6DjlIndex'
						});
						}else{
						return this.$router.push({
							path: '/Jlindex'
						});
						}
					case 'pvcl':
						return this.$router.push({
							path: '/GzIndex'
						});
				}
			},
			openWeb(row) {
				const data = {
					user: this.UserInfo[1],
					event: "点击web访问" + row.project_name
				}
				log_add(data)
				let webUrl = row.url;
				// console.log(webUrl,"++++++++");
				if (!!webUrl) {
					var ua = navigator.userAgent;
					var Browser_Agent = navigator.userAgent;
					if (Browser_Agent.indexOf("Edg") != -1) {
						window.open(webUrl);
					} else {
						window.open("microsoft-edge:" + webUrl);
					}

				} else {
					this.$message.error("该项目暂无此功能");
				}
			},
			// 打开远程桌面
			openmstsc(row) {
				// console.log('点击一次')
				let result = this.authInfo.filter(item => item.id === row.device_name + '_w')
				if (result.length == '1') {
					const data = {
						user: this.UserInfo[1],
						event: "使用远程桌面" + row.project_name
					}
					log_add(data)
					this.dialogVisible = true;
					if (row.sysip) {
						this.formData.sysip = row.sysip;
					} else {
						this.formData.sysip = '暂未录入';
					}
					if (row.syspassword) {
						this.formData.syspassword = row.syspassword;
					} else {
						this.formData.syspassword = '暂未录入';
					}
					console.log(this.formData.sysip, "运行到此")
				} else {
					this.$message.success("您没有操作远程桌面的权限");
				}
			},
			onCopyIP() {
				//创建input标签
				var input = document.createElement('input')
				//将input的值设置为需要复制的内容
				input.value = this.formData.sysip;
				// console.log(this.formData.syspassword);
				if (this.formData.sysip == null) {
					this.$message.error("系统IP为空，请联系管理员！");
				} else {
					//添加input标签
					document.body.appendChild(input)
					//选中input标签
					input.select()
					//执行复制
					document.execCommand('copy')
					//成功提示信息
					this.$message.success('IP复制成功！')
					//移除input标签
					document.body.removeChild(input)
					// this.copyFunc(this.formData.syspassword)
					// this.dialogVisible = false;
					// var command = "mstsc /v:192.168.1.171 /admin /f" //这里是执行的DOS命令
					// window.open("TestCallEXE://")
				}
			},
			onCopy() {
				//创建input标签
				var input = document.createElement('input')
				//将input的值设置为需要复制的内容
				input.value = this.formData.syspassword;
				// console.log(this.formData.syspassword);
				if (this.formData.syspassword == null) {
					this.$message.error("密码为空，请联系管理员！");
				} else {
					//添加input标签
					document.body.appendChild(input)
					//选中input标签
					input.select()
					//执行复制
					document.execCommand('copy')
					//成功提示信息
					this.$message.success('密码复制成功！')
					//移除input标签
					document.body.removeChild(input)
					// this.copyFunc(this.formData.syspassword)
					// this.dialogVisible = false;
					// var command = "mstsc /v:192.168.1.171 /admin /f" //这里是执行的DOS命令
					// window.open("TestCallEXE://")
				}
			},
			runcmd(value) {
				// console.log('222' + value)
				let WSH = new ActiveXObject("WScript.Shell");
				WSH.run(value);
				cmd = null;
			},
			onSubmit() {
				console.log('submit!');
			},
			handleClose(done) {
				this.$confirm('确定关闭吗').then(() => {
					// function(done)，done 用于关闭 Dialog
					done();
				}).catch(() => {

				});
			},
		}
	}
</script>
<style scoped lang="scss">
	.addform {
		padding: 0 2.5vw;
	}

	.main {
		width: 100vw;
		height: 92vh;
		overflow: hidden;
		background-color: #132735;

		// border:#168db2 1px solid;
		.mian-center {
			width: 90vw;
			height: 86vh;
			margin-left: 5vw;
			background-color: #071c2b;
			box-shadow: 0vh 1vh 2vh 0vh rgba(83, 93, 113, 0.06);
			border-radius: 1vh;
			opacity: 0.95;
		}

		.chaxu {
			margin-top: 5.8vh;
			height: 5vh;
			width: 90vw;
			// border:#168db2 1px solid;
			line-height: 5vh;
		}

		.chaxu-row1 {
			width: 8vw;
			height: 2vh;
			line-height: 5vh;
			font-family: PingFang-SC-Regular;
			font-size: 3vh;
			font-weight: normal;
			font-stretch: normal;
			line-height: 2vh;
			letter-spacing: 0vh;
			color: #6acafb;
			// border:#168db2 1px solid;
		}

		.top {
			height: 22vh;
			width: 90vw;
		}

		.top-row1 {
			height: 20vh;
			width: 24vw;
			margin-top: 2vh;
			background-color: #004b6e20;
			border-radius: 0vh;
			// opacity: 0.2;
			margin-left: 3vw;

			.lable1 {
				width: 8vw;
				height: 13vh;
				font-size: 8vh;
				line-height: 13vh;
				letter-spacing: 0vh;
				color: #ffffff;
				margin-top: 1vh;
				margin-left: 2vw;
			}

			.lable2 {
				width: 13vw;
				height: 13vh;
				font-family: PingFang-SC-Regular;
				font-size: 1.5vw;
				font-weight: normal;
				font-stretch: normal;
				line-height: 13vh;
				letter-spacing: 0vh;
				margin-top: 5vh;
				margin-left: 1vw;
				color: #6acafb;
			}

			.name {
				font-size: 1.2vw;
				line-height: 6vh;
				// font-weight: bold;
				color: #6acafb;
				// line-height: 3vh;
				margin-left: 0.5vw;
				font-family: "MF-ZheHei-Noncommercial-Regular";
			}

			.num {
				width: 3vw;
				height: 6vh;
				line-height: 6vh;
				text-align: center;
				background: url("~@/assets/images/map/map3.png") no-repeat 0 0;
				background-size: 100% 100%;
				color: #fff;
				font-size: 5vh;
				margin-left: 0.5vw;
			}
		}

		.fl {
			float: left;
		}

		.top-row2 {
			height: 22vh;
			width: 25vw;
			// border: 1px salmon solid;
			margin-left: 2vw;
			margin-top: 0.5vh;
		}

		.top-row3 {
			height: 22vh;
			width: 30vw;
			//  border:1px salmon solid;
			margin-left: 2vw;
		}



	}

	::v-deep {
		.el-table::before {
			background-color: #071c2b !important;
		}

		.el-pager li.active {
			color: #409EFF;
			cursor: default;
		}

		.el-form-item__label:before {
			content: none !important;
		}

		.el-button--small {
			margin-top: 10px;
			margin-bottom: 10px;
		}


		.addPro {
			background-image: url("~@/assets/images/rfl_switch.png");
			background-size: 100% 100%;
			height: 78vh;

			.el-dialog__header {
				display: none;
			}

			.el-dialog__body {
				padding: 0px;
			}

			.addBox {
				.evaluate-header {
					height: 7vh;
					// background: #5DCEF3;
					line-height: 6vh;
					//  background: #58cdf4;
					//  line-height: 50px;
					text-align: center;
					color: #0ef5fd;
					font-size: 3vh;
					position: relative;
					border-radius: 5px 5px 0 0;

					.evaluate-header-icon {
						width: 2vw;
						height: 2vh;
						position: absolute;
						right: 1vw;
						margin-top: -6vh;
						cursor: pointer;
					}

				}

				.flex {
					margin-top: 5vh;
				}

				.el-input {
					margin-bottom: 3vh;
				}

				.btn {
					width: 8vw;
					height: 5vh;
					background-color: #168db2;
					border-radius: 0.1vh;
					border: none;
					color: #fff;
					margin: 2vh auto;
					text-align: center;
					line-height: 5vh;
					cursor: pointer;
				}
			}
		}

		.el-icon-arrow-up {
			line-height: 20px !important;
		}

		.el-form-item {
			display: inline-block !important;
		}

		.el-form-item__label {
			width: 150px;
			color: #168db2;
		}

		.el-input {
			width: 13vw;
			height: 30px;
		}

		.el-input__inner {
			background-color: #0c202f;
			height: 30px;
			border: 0.1px solid #168db2;
			color: #fff;
		}

		.main-table {
			height: 55vh;
			width: 88vw;
			margin-left: 1vw;
			margin-top: 1vh;
			// border: 1px solid white;
			font-family: PingFang-SC-Regular;

			.table-h {
				height: 55vh;
				// margin-left: 1vw;
				background-color: #0c202f;
				font-family: PingFang-SC-Regular;

				th {
					padding: 0 !important;
					height: 4vh;
					line-height: 4vh;
					background-color: #183f54;
					color: #6acafb;
					font-size: 1.8vh;
					font-family: PingFang-SC-Regular;
				}

				td {
					padding: 0 !important;
					height: 4vh;
					line-height: 4vh;
					background-color: #0c202f;
					color: #fff;
				}
			}

			.jiange {
				margin-top: 0.1vh;
				background-color: #0c202f;
			}
		}



		.anyDeskPop {
			width: 35vw !important;
			// height: 40vh!important;
			background-image: url("~@/assets/images/rfl_switch.png");
			background-size: 100% 100%;
			// position: absolute;
			// left: 50%;
			// top: 50%;
			// margin: -20vh 0 0 -15vw;
			// margin-top: -20vh!important;

			.PopHt {
				color: #fff;

				.param-header-icon {
					width: 1.5vw;
					height: 1.5vh;
					position: absolute;
					right: 1vw;
					top: 0.5vh;
					cursor: pointer;
				}

				.PopHt-tit {
					width: 33vw;
					text-align: center;
					line-height: 2.7vh;
					font-size: 2.7vh;
					position: absolute;
					top: 0.6vh;
				}

				.PopHt-note {
					width: 33vw;
					// text-align: center;
					line-height: 1.7vh;
					font-size: 1.3vw;
					margin-left: 5.2vw;
					margin-top:1vh;
					position: absolute;
					// top: 0.6vh;
				}

				.PopHt-con {
					height: 25vh;

					div {
						div {
							padding-bottom: 2vh;
						}
					}
				}

				.dialog-content {
					margin-top: 5vh;
				}

			}
		}



		.el-table td,
		.el-table th.is-leaf,
		.el-table--border,
		.el-table--group {
			border-color: #183f56;
			border-right: 1px solid #183f56;
		}

		.el-table__body {
			border-left: 1px solid #183f56;
		}

		.el-table--border::after,
		.el-table--group::after,
		.el-table::before {
			background-color: #183f56;
		}

		.el-table tbody tr:hover>td {
			background-color: #168db2;
		}

		.subtn {
			width: 8vw;
			// height: 5vh;
			line-height: 1vh;
			background-color: #307b91;
			border-radius: 0.1vh;
			border: none;
			margin-left: 1vw;
		}
	}
</style>